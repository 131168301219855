<template>
  <div class="classtrackAnalysis">
    <div class="search">
      <a-tree-select
        class="search-style"
        style="width: 250px"
        v-model="OrgIds"
        :tree-data="treeData"
        tree-checkable
        :maxTagCount="1"
        placeholder="请选择搜索范围"
        @change="change"
        :show-checked-strategy="SHOW_PARENT"
      />

      <!--@change="handleChange"-->
      <a-select
        class="search-style"
        style="width: 200px"
        v-model="classTypeId"
        v-if="class_category"
        placeholder="课程类型"
      >
        <a-select-option value="">全部课程类型</a-select-option>
        <a-select-option
          v-for="item in class_category"
          :value="item.Id"
          :disabled="item.isChild == 0"
        >
          <span v-if="item.isChild == 0">
            {{ item.Name }}
          </span>
          <span v-else>&nbsp;&nbsp;{{ item.Name }}</span>
        </a-select-option>
      </a-select>
      <!--@change="changeIsLive"-->
      <a-select class="search-style" v-model="IsLive" placeholder="上课类型">
        <a-select-option value="">全部上课类型</a-select-option>
        <a-select-option value="1">直播</a-select-option>
        <a-select-option value="2">线下</a-select-option>
      </a-select>
      <!--@change="changePreplan"-->
      <a-select class="search-style" v-model="Preplan" placeholder="是否预排课">
        <a-select-option value="">全部预排课</a-select-option>
        <a-select-option value="1">是</a-select-option>
        <a-select-option value="0">否</a-select-option>
      </a-select>
      <!--@change="changeVip"-->

      <a-select class="search-style" v-model="IsVip" placeholder="班级类型">
        <a-select-option value="">全部班级类型</a-select-option>
        <a-select-option value="0">普通班级</a-select-option>
        <a-select-option value="1">VIP班级</a-select-option>
      </a-select>
      <a-range-picker
        class="search-style"
        style="width: 200px"
        :default-value="[
          moment(StartDate, 'YYYY-MM-DD'),
          moment(EndDate, 'YYYY-MM-DD')
        ]"
        format="YYYY-MM-DD"
        :placeholder="['开始时间', '结束时间']"
        allowClear
        @change="ChangeSearchTime"
      />
      <a-button class="search-style" @click="_clear">重置</a-button>
      <a-button type="primary" @click="_search">查询</a-button>
    </div>
    <!---->
    <div
      :style="
        'width:' +
          ($store.state.app.showNav ? pageWidth - 220 : pageWidth) +
          'px;overflow: auto'
      "
    >
      <a-table
        :pagination="false"
        :columns="columns"
        bordered
        :data-source="data"
        :scroll="{ x: 1850, y: 500 }"
      >
      </a-table>
    </div>
    <div
      :style="
        'width:' +
          ($store.state.app.showNav ? pageWidth - 220 : pageWidth) +
          'px;overflow: auto'
      "
      class="tableData"
    >
      <a-table
        :pagination="false"
        :columns="columns2"
        bordered
        :data-source="tableData"
        :scroll="{ x: 4700, y: 500 }"
      >
      </a-table>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import elementResizeDetectorMaker from 'element-resize-detector'
import { mapState } from 'vuex'
export default {
  name: 'classtrackAnalysis',
  data () {
    return {
      userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
      columns: [
        {
          title: '中心',
          dataIndex: 'OrgName',
          key: 'OrgName',
          align: 'left',
          width: 200,
          fixed: 'left',
          ellipsis: true
        },
        {
          title: '总计',
          children: [
            {
              title: '班级',
              dataIndex: 'TotalClassNum',
              key: 'TotalClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '人数',
              dataIndex: 'TotalStudentNum',
              key: 'TotalStudentNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '出勤',
              dataIndex: 'TotalAttendanceNum',
              key: 'TotalAttendanceNum',
              width: 80
              // ellipsis:true
            }
          ]
        },
        {
          title: '平均(星期一)',
          children: [
            {
              title: '班级',
              dataIndex: 'ClassNum1',
              key: 'ClassNum1',
              width: 80
              // ellipsis:true
            },
            {
              title: '人数',
              dataIndex: 'StudentNum1',
              key: 'StudentNum1',
              width: 60
              // ellipsis:true
            },
            {
              title: '出勤',
              dataIndex: 'AttendanceNum1',
              key: 'AttendanceNum1',
              width: 60
              // ellipsis:true
            }
          ]
        },
        {
          title: '平均(星期二)',
          children: [
            {
              title: '班级',
              dataIndex: 'ClassNum2',
              key: 'ClassNum2',
              width: 80
              // ellipsis:true
            },
            {
              title: '人数',
              dataIndex: 'StudentNum2',
              key: 'StudentNum2',
              width: 60
              // ellipsis:true
            },
            {
              title: '出勤',
              dataIndex: 'AttendanceNum2',
              key: 'AttendanceNum2',
              width: 60
              // ellipsis:true
            }
          ]
        },
        {
          title: '平均(星期三)',
          children: [
            {
              title: '班级',
              dataIndex: 'ClassNum3',
              key: 'ClassNum3',
              width: 80
              // ellipsis:true
            },

            {
              title: '人数',
              dataIndex: 'StudentNum3',
              key: 'StudentNum3',
              width: 60
              // ellipsis:true
            },
            {
              title: '出勤',
              dataIndex: 'AttendanceNum3',
              key: 'AttendanceNum3',
              width: 60
              // ellipsis:true
            }
          ]
        },
        {
          title: '平均(星期四)',
          children: [
            {
              title: '班级',
              dataIndex: 'ClassNum4',
              key: 'ClassNum4',
              width: 80
              // ellipsis:true
            },

            {
              title: '人数',
              dataIndex: 'StudentNum4',
              key: 'StudentNum4',
              width: 60
              // ellipsis:true
            },
            {
              title: '出勤',
              dataIndex: 'AttendanceNum4',
              key: 'AttendanceNum4',
              width: 60
              // ellipsis:true
            }
          ]
        },
        {
          title: '平均(星期五)',
          children: [
            {
              title: '班级',
              dataIndex: 'ClassNum5',
              key: 'ClassNum5',
              width: 80
              // ellipsis:true
            },
            {
              title: '人数',
              dataIndex: 'StudentNum5',
              key: 'StudentNum5',
              width: 60
              // ellipsis:true
            },
            {
              title: '出勤',
              dataIndex: 'AttendanceNum5',
              key: 'AttendanceNum5',
              width: 60
              // ellipsis:true
            }
          ]
        },
        {
          title: '平均(星期六)',
          children: [
            {
              title: '班级',
              dataIndex: 'ClassNum6',
              key: 'ClassNum6',
              width: 80
              // ellipsis:true
            },
            {
              title: '人数',
              dataIndex: 'StudentNum6',
              key: 'StudentNum6',
              width: 60
              // ellipsis:true
            },
            {
              title: '出勤',
              dataIndex: 'AttendanceNum6',
              key: 'AttendanceNum6',
              width: 60
              // ellipsis:true
            }
          ]
        },
        {
          title: '平均(星期日)',
          children: [
            {
              title: '班级',
              dataIndex: 'ClassNum7',
              key: 'ClassNum7',
              width: 80
              // ellipsis:true
            },
            {
              title: '人数',
              dataIndex: 'StudentNum7',
              key: 'StudentNum7',
              width: 60
              // ellipsis:true
            },
            {
              title: '出勤',
              dataIndex: 'AttendanceNum7',
              key: 'AttendanceNum7',
              width: 60
              // ellipsis:true
            }
          ]
        }
      ],
      data: [],
      StartDate: '',
      EndDate: '',
      treeData: [],
      pageWidth: '',
      OrgIds: [],
      OrgName: [],
      ParentId: [],
      SHOW_PARENT: 'SHOW_ALL',
      class_category: '',
      classTypeId: '',
      createValue: [],
      IsLive: '',
      Preplan: '',
      IsVip: '',
      columns2: [
        {
          title: '中心',
          dataIndex: 'OrgName',
          key: 'OrgName',
          align: 'left',
          width: 200,
          fixed: 'left',
          ellipsis: true
        },
        {
          title: '班级人数合计',
          children: [
            {
              title: 'VIP',
              dataIndex: 'TotalVipNum',
              key: 'TotalVipNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '1',
              dataIndex: 'Total1Num',
              key: 'Total1Num',
              width: 80
              // ellipsis:true
            },
            {
              title: '2',
              dataIndex: 'Total2Num',
              key: 'Total2Num',
              width: 80
              // ellipsis:true
            },
            {
              title: '3',
              dataIndex: 'Total3Num',
              key: 'Total3Num',
              width: 80
              // ellipsis:true
            },
            {
              title: '4',
              dataIndex: 'Total4Num',
              key: 'Total4Num',
              width: 80
              // ellipsis:true
            },
            {
              title: '5',
              dataIndex: 'Total5Num',
              key: 'Total5Num',
              width: 80
              // ellipsis:true
            },
            {
              title: '6',
              dataIndex: 'Total6Num',
              key: 'Total6Num',
              width: 80
              // ellipsis:true
            }
          ]
        },
        {
          title: '星期一',
          children: [
            {
              title: 'VIP',
              dataIndex: 'Week1StudentVipClassNum',
              key: 'Week1StudentVipClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '1',
              dataIndex: 'Week1Student1ClassNum',
              key: 'Week1Student1ClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '2',
              dataIndex: 'Week1Student2ClassNum',
              key: 'Week1Student2ClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '3',
              dataIndex: 'Week1Student3ClassNum',
              key: 'Week1Student3ClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '4',
              dataIndex: 'Week1Student4ClassNum',
              key: 'Week1Student4ClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '5',
              dataIndex: 'Week1Student5ClassNum',
              key: 'Week1Student5ClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '6',
              dataIndex: 'Week1Student6ClassNum',
              key: 'Week1Student6ClassNum',
              width: 80
              // ellipsis:true
            }
          ]
        },
        {
          title: '星期二',
          children: [
            {
              title: 'VIP',
              dataIndex: 'Week2StudentVipClassNum',
              key: 'Week2StudentVipClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '1',
              dataIndex: 'Week2Student1ClassNum',
              key: 'Week2Student1ClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '2',
              dataIndex: 'Week2Student2ClassNum',
              key: 'Week2Student2ClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '3',
              dataIndex: 'Week2Student3ClassNum',
              key: 'Week2Student3ClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '4',
              dataIndex: 'Week2Student4ClassNum',
              key: 'Week2Student4ClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '5',
              dataIndex: 'Week2Student5ClassNum',
              key: 'Week2Student5ClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '6',
              dataIndex: 'Week2Student6ClassNum',
              key: 'Week2Student6ClassNum',
              width: 80
              // ellipsis:true
            }
          ]
        },
        {
          title: '星期三',
          children: [
            {
              title: 'VIP',
              dataIndex: 'Week3StudentVipClassNum',
              key: 'Week3StudentVipClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '1',
              dataIndex: 'Week3Student1ClassNum',
              key: 'Week3Student1ClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '2',
              dataIndex: 'Week3Student2ClassNum',
              key: 'Week3Student2ClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '3',
              dataIndex: 'Week3Student3ClassNum',
              key: 'Week3Student3ClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '4',
              dataIndex: 'Week3Student4ClassNum',
              key: 'Week3Student4ClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '5',
              dataIndex: 'Week3Student5ClassNum',
              key: 'Week3Student5ClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '6',
              dataIndex: 'Week3Student6ClassNum',
              key: 'Week3Student6ClassNum',
              width: 80
              // ellipsis:true
            }
          ]
        },
        {
          title: '星期四',
          children: [
            {
              title: 'VIP',
              dataIndex: 'Week4StudentVipClassNum',
              key: 'Week4StudentVipClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '1',
              dataIndex: 'Week4Student1ClassNum',
              key: 'Week4Student1ClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '2',
              dataIndex: 'Week4Student2ClassNum',
              key: 'Week4Student2ClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '3',
              dataIndex: 'Week4Student3ClassNum',
              key: 'Week4Student3ClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '4',
              dataIndex: 'Week4Student4ClassNum',
              key: 'Week4Student4ClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '5',
              dataIndex: 'Week4Student5ClassNum',
              key: 'Week4Student5ClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '6',
              dataIndex: 'Week4Student6ClassNum',
              key: 'Week4Student6ClassNum',
              width: 80
              // ellipsis:true
            }
          ]
        },
        {
          title: '星期五',
          children: [
            {
              title: 'VIP',
              dataIndex: 'Week5StudentVipClassNum',
              key: 'Week5StudentVipClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '1',
              dataIndex: 'Week5Student1ClassNum',
              key: 'Week5Student1ClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '2',
              dataIndex: 'Week5Student2ClassNum',
              key: 'Week5Student2ClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '3',
              dataIndex: 'Week5Student3ClassNum',
              key: 'Week5Student3ClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '4',
              dataIndex: 'Week5Student4ClassNum',
              key: 'Week5Student4ClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '5',
              dataIndex: 'Week5Student5ClassNum',
              key: 'Week5Student5ClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '6',
              dataIndex: 'Week5Student6ClassNum',
              key: 'Week5Student6ClassNum',
              width: 80
              // ellipsis:true
            }
          ]
        },
        {
          title: '星期六',
          children: [
            {
              title: 'VIP',
              dataIndex: 'Week6StudentVipClassNum',
              key: 'Week6StudentVipClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '1',
              dataIndex: 'Week6Student1ClassNum',
              key: 'Week6Student1ClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '2',
              dataIndex: 'Week6Student2ClassNum',
              key: 'Week6Student2ClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '3',
              dataIndex: 'Week6Student3ClassNum',
              key: 'Week6Student3ClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '4',
              dataIndex: 'Week6Student4ClassNum',
              key: 'Week6Student4ClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '5',
              dataIndex: 'Week6Student5ClassNum',
              key: 'Week6Student5ClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '6',
              dataIndex: 'Week6Student6ClassNum',
              key: 'Week6Student6ClassNum',
              width: 80
              // ellipsis:true
            }
          ]
        },
        {
          title: '星期日',
          children: [
            {
              title: 'VIP',
              dataIndex: 'Week7StudentVipClassNum',
              key: 'Week7StudentVipClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '1',
              dataIndex: 'Week7Student1ClassNum',
              key: 'Week7Student1ClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '2',
              dataIndex: 'Week7Student2ClassNum',
              key: 'Week7Student2ClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '3',
              dataIndex: 'Week7Student3ClassNum',
              key: 'Week7Student3ClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '4',
              dataIndex: 'Week7Student4ClassNum',
              key: 'Week7Student4ClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '5',
              dataIndex: 'Week7Student5ClassNum',
              key: 'Week7Student5ClassNum',
              width: 80
              // ellipsis:true
            },
            {
              title: '6',
              dataIndex: 'Week7Student6ClassNum',
              key: 'Week7Student6ClassNum',
              width: 80
              // ellipsis:true
            }
          ]
        }
      ],
      tableData: []
    }
  },
  computed: {
    ...mapState({
      showNav: state => state.app.showNav
    })
  },

  created () {
    this.pageWidth = document.documentElement.clientWidth - 220
    this.StartDate = this.getMonday('s', -1)
    this.EndDate = this.getMonday('e', -1)
    this._treeData()
    this._class_category()
    this.loadData()
    this._tableList()
    // this.formatDate();
    console.log(this.StartDate)
    console.log(this.EndDate)
  },
  methods: {
    moment,
    formatDate () {
      let date = new Date()
      let year = date.getFullYear() // 年
      let month = date.getMonth() + 1 // 月
      let day = date.getDate() // 日
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      this.StartDate = year + '-' + month + '-01'
      this.EndDate = year + '-' + month + '-' + day
    },
    getMonday (type, dates) {
      var now = new Date()
      var nowTime = now.getTime()
      var day = now.getDay()
      var longTime = 24 * 60 * 60 * 1000
      var n = longTime * 7 * (dates || 0)
      if (type == 's') {
        var dd = nowTime - (day - 1) * longTime + n
      }
      if (type == 'e') {
        var dd = nowTime + (7 - day) * longTime + n
      }
      dd = new Date(dd)
      var y = dd.getFullYear()
      var m = dd.getMonth() + 1
      var d = dd.getDate()
      m = m < 10 ? '0' + m : m
      d = d < 10 ? '0' + d : d
      var day = y + '-' + m + '-' + d
      // console.log(day);
      return day
    },
    _class_category () {
      let self = this
      this.$axios.get(1103, {}, res => {
        if (res.data.code == 1) {
          self.class_category = res.data.data
        }
      })
    },
    _treeData () {
      let self = this
      this.$axios.get(
        1090,
        {
          UserId: self.userinfo.uid
        },
        res => {
          self.treeData = res.data
        }
      )
    },
    _tableList () {
      let self = this
      this.$message.loading('加载中...')
      this.$axios.post(
        1258,
        {
          StartDate: self.StartDate,
          EndDate: self.EndDate,
          OrgIds: self.OrgIds.length > 0 ? JSON.stringify(self.OrgIds) : '',
          UserId: self.userinfo.uid,
          OrgName: self.OrgName.length > 0 ? JSON.stringify(self.OrgName) : '',
          ParentId:
            self.ParentId.length > 0 ? JSON.stringify(self.ParentId) : '',
          classTypeId: self.classTypeId,
          IsLive: self.IsLive,
          Preplan: self.Preplan,
          IsVip: self.IsVip
        },
        res => {
          this.$message.destroy()
          if (res.data.code == 0) {
            self.tableData = res.data.data
          } else {
            self.tableData = []
            this.$message.error(res.data.msg)
          }
        }
      )
    },
    loadData () {
      let self = this
      this.$message.loading('加载中...')
      this.$axios.post(
        1256,
        {
          StartDate: self.StartDate,
          EndDate: self.EndDate,
          OrgIds: self.OrgIds.length > 0 ? JSON.stringify(self.OrgIds) : '',
          UserId: self.userinfo.uid,
          OrgName: self.OrgName.length > 0 ? JSON.stringify(self.OrgName) : '',
          ParentId:
            self.ParentId.length > 0 ? JSON.stringify(self.ParentId) : '',
          classTypeId: self.classTypeId,
          IsLive: self.IsLive,
          Preplan: self.Preplan,
          IsVip: self.IsVip
        },
        res => {
          this.$message.destroy()
          if (res.data.code == 0) {
            self.data = res.data.data
          } else {
            self.data = []
            this.$message.error(res.data.msg)
          }
        }
      )
    },
    changeIsLive (value) {
      this.IsLive = value
    },
    changePreplan (value) {
      this.Preplan = value
    },
    changeVip (value) {
      this.IsVip = value
    },
    change (value, label, text) {
      if (text.checked) {
        this.ParentId.push(text.triggerValue)
      } else {
        if (this.ParentId.length > 0) {
          for (var i = 0; i < this.ParentId.length; i++) {
            if (text.triggerValue == this.ParentId[i]) {
              this.ParentId.splice(i, 1)
            }
          }
        }
      }

      this.OrgName = label
      this.OrgIds = value
    },
    handleChange (value) {
      this.classTypeId = value
    },
    _search () {
      this.loadData()
      this._tableList()
    },
    _clear () {
      this.classTypeId = ''
      this.OrgIds = []
      this.OrgName = []
      // this.createValue = [];
      // this.StartDate = '';
      // this.EndDate = '';
      this.StartDate = this.getMonday('s', -1)
      this.EndDate = this.getMonday('e', -1)
      this.ParentId = []
      this.IsLive = ''
      this.IsVip = ''
      this.Preplan = ''
      this.formatDate()
      this.loadData()
      this._tableList()
    },
    ChangeSearchTime (time, timeString) {
      // this.createValue = time;
      this.StartDate = timeString[0]
      this.EndDate = timeString[1]
    }
  }
}
</script>

<style scoped lang="less">
.classtrackAnalysis {
  background: #ffffff;
  margin-top: 15px;
  min-height: 800px;
  padding: 15px;
}

.search {
  margin-bottom: 20px;
  display: flex;
  .search-style {
    /*display: inline-block;*/
    width: 120px;
    margin-right: 15px;
  }
}
.classtrackAnalysis .ant-table-tbody > tr > td {
  padding: 0 !important;
}
.tableData {
  margin-top: 50px;
}
</style>
